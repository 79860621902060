/* EH Sans */
@font-face {
  font-family: "EH Sans";
  src: url("./EH_SansReg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EH Sans";
  src: url("EH_SansRegIta.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "EH Sans";
  src: url("EH_SansBol.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "EH Sans";
  src: url("EH_SansBolIta.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "EH Sans";
  src: url("EH_SansDem.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EH Sans";
  src: url("EH_SansDemIta.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "EH Sans";
  src: url("EH_SansLig.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "EH Sans";
  src: url("EH_SansLigIta.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* EH Serif */
@font-face {
  font-family: "EH Serif";
  src: url("EH_SerifReg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EH Serif";
  src: url("EH_SerifRegIta.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "EH Serif";
  src: url("EH_SerifBol.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "EH Serif";
  src: url("EH_SerifBolIta.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "EH Serif";
  src: url("EH_SerifDem.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EH Serif";
  src: url("EH_SerifDemIta.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "EH Serif";
  src: url("EH_SerifLig.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "EH Serif";
  src: url("EH_SerifLigIta.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
